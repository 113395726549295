// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;900&display=swap');

//Fonts
@import "_fonts.scss";

//VUE TOOLTIP
@import "vue-tooltip.scss";

//DROPZONE
@import "~vue2-dropzone/dist/vue2Dropzone.min.css";

//Multiselect
@import "components/multiselect";

// Variables
//@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "_variables.scss";

// Structure
@import "structure/_general.scss";
@import "structure/_left-menu.scss";
@import "structure/_topbar.scss";
@import "structure/_page-head.scss";
@import "structure/_footer.scss";
@import "structure/_right-sidebar.scss";
@import "structure/_layouts.scss";

//Components
@import "components/_helper.scss";
@import "components/_social.scss";
@import "components/_widgets.scss";
@import "components/_custom-checkbox.scss";
@import "components/_custom-radio.scss";
@import "components/_ribbons.scss";
@import "components/_print.scss";
@import "components/_preloader.scss";

@import "~hamburgers/dist/hamburgers.css";


@font-face {
    font-family: 'materialdesignicons';
    src: url("~@mdi/font/fonts/materialdesignicons-webfont.ttf");
}
@import "~@mdi/font/scss/materialdesignicons";

.dropzone .dz-preview{
    z-index: 0;
}

.afluencia-nav{
    background: #2e3338;
    border-bottom: none !important;
}
.card-body{
    //background: #4c4e4f;
    color: #fff;
}
.card{
    border:0px;
}

.mygrey{
color: #8c9195;
}

.red_danger{
    background-color: red;
}
.orange_warning{
    background-color: orangered;
}
.white{
    color: #fff !important;
}
.circle {
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%;
    /* the magic whoosh */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 auto 40px;
    /* border: 5px solid gray; */

}
.red_off_on_green{
    background-color: #443a3a;
    margin-top: 10px;
    position: absolute;
}
.red_off_on_yellow{
    background-color: #443a3a;
    margin-top: 10px;
    position: absolute;
    margin-left: 15px;
}

.yellow_off_on_red{
    background-color: #48452b;
    margin-top: -20px;
    position: absolute;
    margin-left: 16px;
}
.yellow_off_on_green{
    background-color: #48452b;
    margin-top: 230px;
    position: absolute;
}

.green_off_on_yellow{
    background-color: #353e35;;
    margin-top: -20px;
    position: absolute;
    margin-left: 16px;
}
.green_off_on_red{
    background-color: #353e35;
    margin-top: 195px;
    position: absolute;
    margin-left: 16px;
}


.green {
    background-color: #09B75D;
    margin-top: 455px;
    position: absolute;
}

.yellow {
    background-color: #EFCB18;
    margin-top: 230px;
}

.red {
    background-color: red;
    margin-top: 10px;
}

.semaforo {
    background-color: rgb(70, 70, 70);
    border: 10px solid grey;
    height: 43em;
    margin-top: 60px;
    width: 250px;
    position: fixed;
    border-radius: 150px;
    top: 50%;
    left: 15%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.message {
    height: 100px;
    //position: absolute;
    top: 0;
    bottom: 0;
    left: 500px;
    right: 0;
    margin: auto;
    font-weight: bold;
    color:  #fff;
}

.counter-btn{
    width: 100px;
    padding: 1.5rem 1.5rem;
    line-height: 1;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    font-size: 30pt;
    font-weight: bold;
}

.decrease{

    background-color: red;
    border-color: red;
}
.increase{
    background-color: green;
    border-color: green;
}

.number-input{
    font-size: 25pt;
    width: 100px;
    text-align: center;
}

.white{
    color:#fff;
}

.text-danger {
    color:red !important;
}
.orangered{
    color: orangered;
}

.warning-box{
    background-color: red;
}
